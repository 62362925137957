<template>
  <div id="photo_download">
    <mainMenu></mainMenu>
    <div class="downloadBlock">
      <div class="wrapper" v-if="photoItem">
        <div class="downloadFileInfo">
          <span class="fileName">{{ photoItem.title }}{{ photoItem.extention ? "." + photoItem.extention : "" }}</span>
          <span class="paramsInfo">{{ photoItem.width }}x{{ photoItem.height }}px</span>
          <span class="sizeInfo">{{ photoItem.size | toFileSize }}</span>
        </div>
        <div class="previewBlock">
          <div class="previewBlock-img">
            <img :src="photoItem.preview" alt="" />
          </div>
          <div class="previewBlock-download">
            <a :href="photoItem.download" class="downloadBtn" :download="photoItem.title">
              <img src="./../../assets/images/downloadArrowDown.png" alt="" />
              Скачать
            </a>
            <div class="advertisingBlock">
              <div>{{ banner }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper" v-else>
        <span class="error_message">Sorry photo is not found!</span>
      </div>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";

export default {
  name: "PhotoDownload",
  components: { mainMenu, mainFooter },
  data() {
    return {
      endpoint: "photo_bank/",
      photoItem: {
        title: "",
        extention: "",
        size: "",
        width: "",
        height: "",
        preview: "",
        download: "",
      },
      banner: "Место для вашей рекламы",
    };
  },
  methods: {
    getPhoto() {
      this.$http.get(this.endpoint + this.$route.params.photoId).then((response) => {
        if (response.data.photoBankItem) {
          this.photoItem.title = response.data.photoBankItem.title;
          this.photoItem.extention = response.data.photoBankItem.fileExtention;
          this.photoItem.size = response.data.photoBankItem.size;
          this.photoItem.width = response.data.photoBankItem.width;
          this.photoItem.height = response.data.photoBankItem.height;
          this.photoItem.preview = response.data.photoBankItem.preview;
          this.photoItem.download = response.data.photoBankItem.download;
        } else {
          this.photoItem = null;
          console.log("Photo is not find!");
        }
      });
    },
  },
  created() {
    this.getPhoto();
  },
};
</script>

<style lang="sass">
#photo_download
	display: flex
	flex-direction: column
	min-height: calc(100vh - 155px)
	& .error_message
		font-size: 2rem
		text-align: center
		color: #00ab9e
		margin-top: 50px
	.downloadBlock
		padding: 0 1rem
		min-height: calc(100vh - 300px)
		& .downloadFileInfo
			display: flex
			align-items: baseline
			font-family: 'Roboto'
			font-size: 2rem
			line-height: 2rem
			padding: 1rem 0
			@media screen and (max-width: 1024px)
				font-size: 1rem
				flex-direction: column
				align-items: center
			& .fileName
				color: #8b8989
				font-weight: bold
				margin-right: 1rem
			& .paramsInfo
				color: #c9c8c8
				margin-right: 1rem
			& .sizeInfo
				color: #8b8989
		& .previewBlock
			display: flex
			padding-bottom: 2rem
			@media screen and (max-width: 425px)
				flex-direction: column
			& .previewBlock-img
				display: block
				max-width: 500px
				width: 100%
				& img
					display: block
					width: 100%
					height: 100%
					object-fit: cover
					box-shadow: 2px 2px 13px #cccbcb
			& .previewBlock-download
				display: flex
				flex-direction: column
				align-items: center
				width: 100%
				padding-left: 2rem
				@media screen and (max-width: 425px)
					margin-top: 1rem
					padding-left: 0
				& .downloadBtn
					display: flex
					align-items: center
					justify-content: center
					width: 100%
					height: 100px
					color: #ffffff
					background-color: #1ea59c
					font-family: 'Roboto'
					font-size: 3rem
					padding: 1rem 0
					transition: all 0.3s
					@media screen and (max-width: 768px)
						height: 70px
						font-size: 2rem
					&:hover
						background-color: #10c5b9
					& img
						display: block
						width: auto
						height: 100%
						object-fit: contain
						margin-right: 1rem
				& .advertisingBlock
					display: flex
					align-items: center
					justify-content: center
					width: 100%
					height: 100%
					min-height: 200px
					margin-top: 1rem
					background-color: #ebebeb
</style>
